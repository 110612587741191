import React, { useState, useEffect } from 'react'
import t from 'counterpart'
import Flex from '../Flex'
import { FontIcon, MenuItemLink } from 'react-md'
import { inject, observer } from 'mobx-react'

const Group = ({
  item = [],
  collapsible = false,
  defaultCollapse = true,
  nextGroup = null,
  defaultPath = null,
  isTranslate = false,
  ...props
}) => {
  const [collapse, setCollapse] = useState(collapsible ? defaultCollapse : true)
  const [currentPath, setCurrentPath] = useState(defaultPath || window.location.pathname)
  const isParent = item.children && item.children.length > 0
  const [collapsez, setCollapsez] = useState(false)

  const handleCollapse = () => {
    if (collapsible) {
      setCollapse(!collapse)
    }
  }

  useEffect(() => {
    if (defaultPath) setCurrentPath(defaultPath)
  }, [defaultPath])

  return isParent ? (
    <div className={`menu-group ${isParent && collapsible ? 'collapsible' : ''}`}>
      <Flex
        className="subheader mpk-padding-N padding-top padding-bottom mpk-full full-width"
        onClick={(e) => {
          handleCollapse()
          props.handlePick(props.index)
        }}
        align={Flex.properties.align.CENTER}
        justify={Flex.properties.justify.BETWEEN}
      >
        <div className="mpk-font weight-B">{item.translate ? t.translate(item.label) : item.label}</div>
        {isParent && collapsible && <FontIcon iconClassName={`mdi mdi-menu-${collapse ? 'up' : 'down'}`} />}
      </Flex>
      {collapse && (
        <div className="menus mpk-animation slide-in-down">
          {item.children.map(d => {
            // console.log(d)
            return (
              <>
                <MenuItemLink
                  key={`${item.label}-${d.label}`}
                  href={d.path}
                  className={`menu-item ${currentPath === d.path ? 'active' : (d.childPath && d.childPath.length > 0 && currentPath.match(new RegExp(d.childPath.toString().replace(/,/g, '|'), 'ig')) !== null ? 'active' : '')}`}
                  onClick={e => {
                    if (!d.path.match(/http|https|www/g)) {
                      e.stopPropagation()
                      e.preventDefault()
                      setCurrentPath(d.path)
                      props.temporaryStore.setProperties('defaultPath', d.path)
                      props.navigationStore.redirectTo(d.path)
                      document.title = `${props.envStore.env.appInfo.name} : ${item.label} | ${d.label}`
                      if(d.childrenz){
                        setCollapsez(!collapsez)
                      } else {
                        setCollapsez(false)
                      }
                      // setCollapsez(!collapsez)
                    }
                  }}
                >
                  <Flex
                    align={Flex.properties.align.CENTER}
                    justify={Flex.properties.justify.BETWEEN}
                  >
                    <FontIcon iconClassName={d.iconClassName} />
                    <div className="flex mpk-margin-N margin-left">{d.translate ? t.translate(d.label) : d.label}</div>
                    {d.childrenz && collapsible && <FontIcon iconClassName={`mdi mdi-menu-${collapsez ? 'up' : 'down'}`} />}
                  </Flex>
                </MenuItemLink>
                {(d.childrenz && collapsez) &&
                  <>
                    <div className="menus-z mpk-animation slide-in-down">
                      {d.childrenz.map(z => {
                        return (
                          <>
                            <div
                              key={`${item.label}-${z.label}`}
                              href={z.path}
                              className={`menu-item ${currentPath === z.path ? 'active' : (z.childPath && z.childPath.length > 0 && currentPath.match(new RegExp(z.childPath.toString().replace(/,/g, '|'), 'ig')) !== null ? 'active' : '')}`}
                              onClick={e => {
                                if (!z.path.match(/http|https|www/g)) {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  setCurrentPath(z.path)
                                  props.temporaryStore.setProperties('defaultPath', z.path)
                                  props.navigationStore.redirectTo(z.path)
                                  document.title = `${props.envStore.env.appInfo.name} : ${item.label} | ${z.label}`
                                }
                              }}
                            >
                              <Flex
                                align={Flex.properties.align.CENTER}
                                justify={Flex.properties.justify.BETWEEN}
                              >
                                <FontIcon iconClassName={z.iconClassName} />
                                <div className="p flex mpk-margin-N margin-left">{z.translate ? t.translate(z.label) : z.label}</div>
                              </Flex>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </>
                }
              </>
            )
          })}
        </div>
      )}
    </div>
  ) : (
    nextGroup && nextGroup.children && nextGroup.children.length > 0 && (
      <div className="menu-group-divider mpk-font size-NS mpk-padding-S padding-top padding-bottom">
        {item.translate ? t.translate(item.label) : item.label}
      </div>
    )
  )
}

export default inject('navigationStore', 'envStore', 'temporaryStore')(observer(Group))
