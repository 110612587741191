import React, { useState } from 'react'
import Modal from './Modal';
import { Button, TextIconSpacing, CircularProgress, FontIcon } from 'react-md';
import t from 'counterpart';

function Submit(props) {
  let {
    baseId              = 'react-mpk-modal-submit',
    onRequestClose      = () => {console.warn('[REACT-MPK][MODAL-SUBMIT] on request close is not yet define')},
    onSubmit            = () => {},
    loading             = false,
    additionalFooter    = null,
    showSubmit          = true,
    showCancel          = true,
    submitIconClassName = 'mdi mdi-check',
    submitLabel         = t.translate('mpk.column.submit'),
    ...dialogProps
  } = props;

  return (
    <Modal 
      modal={true}
      onRequestClose={onRequestClose}
      footer={(
        <>
          {showCancel && (
            <Button 
              id={`${baseId}-no`}
              onClick={onRequestClose}
              disabled={loading}
              className="mpk-margin-S margin-right"
            >
              {t.translate('mpk.column.cancel')}
            </Button>
          )}
          {additionalFooter}
          {showSubmit && (
            <Button 
              id={`${baseId}-yes`}
              onClick={onSubmit}
              themeType="contained"
              theme={ loading ? 'disabled' : 'primary'}
              disabled={loading}
            >
              <TextIconSpacing
                icon={loading ? (
                  <CircularProgress id={`${baseId}-submit-progress`} centered={false}/>
                ) : <FontIcon iconClassName={submitIconClassName}/>}
              >
                {submitLabel}
              </TextIconSpacing>
            </Button>
          )}
        </>
      )}
      {...dialogProps}
    />
  )
}

export default Submit;
