var config = {
    onPremise        : true,
    // wsUrl            : 'wss://bunifikasi.staging.pajakku.com/notification/ws',
    // loginUrl         : 'http://286b-182-253-36-30.ngrok.io',
    // loginPath        : 'auth/realms/emeterai/protocol/openid-connect/auth',
    // loginUserInfo    : 'auth/realms/emeterai/protocol/openid-connect/userinfo',
    // loginClientID    : 'emeterai-client',
    // loginScope       : 'openid',
    // loginRedirectUrl : 'http://local.pajakku.com:3000/callback',
    loginKeycloack   : true
}

export default config