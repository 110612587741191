import React, { Component, useEffect } from 'react';
import { Card, Button } from 'react-md';
import Logo from '../Logo/Logo';
import renderHTML from 'react-render-html';
import cookies from 'react-cookies';
import { inject, observer } from 'mobx-react';
import './ErrorPage.scss';

const ErrorPage = ({
  status        = '500', 
  errorMessage  = 'Something wrong', 
  statusText    = 'Please try again later' ,
  showReset     = true,
  showRelogin   = true,
  showLogo      = false,
  reloadLabel   = 'Reload',
  onClearCookie = null,
  // redirectUri   = '/',
  ...props
}) => {
  const redirectUri = sessionStorage.getItem('redirectUri')
  const clearCookie = () => {
    // let { tokenNames } = otherProps.authStore;
    // cookies.remove(tokenNames.accessToken);
    // cookies.remove(tokenNames.refreshToken);
    props.authStore.logout()
  };

  const reset = () => {
    window.location = '/'
  }

  const reload = () => {
    window.location = window.decodeURIComponent(redirectUri || '/');
    sessionStorage.removeItem('redirectUri')
  }

  return (
    <div className="mpk-error-page mpk-flex mpk-full full-height direction-column align-center mpk-padding-N padding-all mpk-content">
      <Card>
        <div className="mpk-flex direction-column-sm">
          <div className="illustration">
            <div className="sign flex-none">
              <div className="board"></div>
              <h1>!</h1>
            </div>
          </div>
          <div className="flex body-content mpk-flex direction-column">
            {showLogo && (
               <Logo
                logoStyle={{
                  height:48
                }}
              />
            )}
            <div className="mpk-flex mpk-margin-N margin-bottom margin-top">
              <div className="status">{status}</div>
              <div className="status-text text-body">{statusText}</div>
            </div>
            <p className="ErrorPage-message mpk-body text-body mpk-dont-break-out">
              {errorMessage ? errorMessage : 'Something wrong. Please try again later'}
            </p>
            <div className="mpk-margin-N top mpk-flex">
              <Button
                theme="primary"
                themeType="contained"
                className="mpk-margin-S right"
                onClick={reload}
              >
                {reloadLabel}
              </Button>
              <span className="flex" />
              {showReset && (
                <Button
                  theme="clear"
                  themeType="outline"
                  className="mpk-margin-S right"
                  onClick={reset}
                >
                  Reset
                </Button>
              )}
              {showRelogin && (
                <Button
                  theme="warning"
                  themeType="contained"
                  className="mpk-margin-S right"
                  onClick={() => onClearCookie ? onClearCookie() : clearCookie()}
                >
                  Re-Login
                </Button>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}


export default inject('authStore')(observer(ErrorPage));