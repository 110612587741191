import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { http, toast } from '../services';
import { merge } from 'lodash';
import qs from 'query-string';
import { LoaderInfo, Modal, Hint } from '../components';
import t from 'counterpart';
import mpkEnv from '../config/env'

const wrapper =  (options={}) => WrappedComponent => {
  let opt = merge({
    url      : `/api/env`,
  }, options)

  function Init(props){
    const [isLoading, setIsLoading ] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null) 
    const requestTokenInterval = useRef()

    const setupApplication = () => {
      let { envStore, authStore } = props;
      return new Promise((resolve, reject) => {
        try {
          let parseWidget = window.location.pathname.match(/widget/g);
          let asWidget = parseWidget ? parseWidget[0] === 'widget' : false;

          const parsedUrl = qs.parseUrl(window.location.href);
          let { 
            accessToken, widgetClientId=null, accessKey, access_key, 
            origin=null, redirectUri=null, client=null, inline='0', clientId=null, 
            requestTokenPeriod=null, companyId=null, applicationType=null,
            ...otherParams
          } = parsedUrl.query;
          
          if(accessToken || accessKey) authStore.clearCookie();
          if(accessToken) authStore.setAccessToken(accessToken);
          if(accessKey || access_key) authStore.setAccessKey(accessKey || access_key);
          if(widgetClientId) http.setClientId(widgetClientId)
          if(clientId) http.setClientId(clientId)
          if(client) http.setClient(client)
          if(companyId) http.setCompanyId(companyId)
          if(applicationType) http.setApplicationType(applicationType)
          if(asWidget){
            if(accessToken && (!client && !widgetClientId)){
              reject(new Error('Client or widget client id is required for authorization with access token'))
            } else {
              envStore.setWidget({
                active: true,
                widgetClientId,
                client,
                parentOrigin: origin,
                redirectUri,
                inline: Number(inline) === 1,
                ...{accessToken, accessKey: accessKey || access_key},
                ...otherParams
              })
              setupListener()
              setupInterval(requestTokenPeriod)
            }
          }
          
          resolve();
        } catch (error) {
          reject(error)
        }
      })
    }

    const getEnvConfiguration = async () => {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await http.request({
            method: 'get',
            url: opt.url
          })

          let { name, publicName } = res.data.appInfo
          document.title = publicName || name

          // SETUP HOST
          const { widget } = props.envStore;
          const { widgetGateway, apiGateway } = res.data;
          let gateway = widget.active ? widgetGateway : apiGateway
          http.setHost(gateway.host)
          http.setBaseUrl(gateway.baseUrl)

          // if(!widget.active)
          //   http.setClientId(apiGateway.clientId);

          // SETUP LOCALE
          const lang = localStorage.getItem('lang')
          let { locale, ...env } = res.data;
          props.envStore.setEnv(env);

          // SET BRAND
          const { channelName } = env.portal
          mpkEnv.setBrand(env.theme, channelName)

          locale.code = lang || locale.code;
          props.envStore.setLocale(locale);
          t.setLocale(locale.code);

          resolve(res.data);
        } catch (error) {
          reject(error);
        }
      })
    }

    const setupInterval = (milis) => {
      if(milis){
        requestTokenInterval.current = setInterval(() => {
          props.envStore.widget.post('request-access-token')
        })
      }
    }

    const setupListener = () => {
      let widget = window
      widget.addEventListener('message', handleMessage, false)
    }

    const handleMessage = (event) => {
      if(event.data){
        window.dispatchEvent(new CustomEvent('custom-message', {detail: event.data}))
      }
    }

    useEffect(() => {
      if(isLoading){
        async function fetchData(){
          try {
            await setupApplication();
            await getEnvConfiguration();
            setIsLoading(false);
          } catch (error) {
            setErrorMessage(typeof error && error.message ?  error.message : error) 
            // window.alert('Server is not running correctly')
          }
        }

        fetchData();
      }

      return () => {
        window.removeEventListener('message', () => {}, null)
      }
    }, [])

    return errorMessage ? <Hint noBorder={true}>{errorMessage}</Hint> : (isLoading ? (
      <LoaderInfo className="mpk-initial-loader">
        Getting application configuration..
      </LoaderInfo>
    ) : (
      <>  
        <WrappedComponent {...props}/>
        <Modal.Confirm 
          {...props.modalStore.confirmation}
          onRequestClose={() => props.modalStore.closeConfirm()}  
        />
        <Modal.Info 
          {...props.modalStore.information}
          onRequestClose={() => {
            let {onRequestClose = () => {}} = props.modalStore.information
            props.modalStore.closeInfo()
            onRequestClose()
          }}
        />
      </>
    ))
  }

  return inject('envStore', 'navigationStore', 'modalStore', 'authStore')(observer(Init));
}

export default wrapper;