import React, { useEffect, useState } from 'react'
import Flex  from '../../../libs/react-mpk/components/Flex'
import { Overlay } from '@react-md/overlay'
import { useToggle } from '@react-md/utils'
import axios from 'axios'

const Loading = ({
  actions           = [],
  baseId            = 'mpk-loading-template-id',
  className         = '',
  label             = 'Loading',
  Loading           = true,
  ...props
}) => {
  const [toggled, , disable, toggle] = useToggle(true);
  let [customStyle, setCustomStyle] = useState({
    appBarColor: null,
    appLogo: null
  })

  useEffect(async () => {
    try {
      var getCustomStyle = await axios.get('/custom/custom.json')
      setCustomStyle(getCustomStyle.data)
    } catch(e){

    }
  }, [])

  return (
    <Flex 
      className={`mpk-form-wrapper mpk-full full-width ${className}`}
      direction={Flex.properties.direction.COLUMN}
      fit
    >
      <div className="loader-wrap">
        <div className="loading">
          <div className="loading-title text-heavy">
            {customStyle.appLogo &&
               <img src={customStyle.appLogo} style={{ height: 48 }} alt=""/>
            }
            {/* {customStyle.appLogo &&
               <img src="./../images/logo-pajakku.png" alt=""/>
            } */}
          </div>
          <span></span>
          <span></span> 
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {/* <Overlay id="simple-overlay" visible={toggled}>
        
      </Overlay> */}
    </Flex>
  )
}

export default Loading
