import React, {useState, useEffect} from 'react'
import { toast } from '../../services'
import Hint from '../Hint'
import LoaderInfo from '../LoaderInfo'
import t from 'counterpart'
import { eventNames } from '../../config/constant'
import Summary from './Summary'

const Summaries = ({
  baseId        = 'mpk-data-view-summaries',
  className     = '',
  channelName   = null,
  onFetchData   = null,
  onInitData    = () => (new Promise(resolve => resolve([]))),
  render        = null,
  defaultQuery  = {},
  autoFetch     = true,
  query         = {},
  getHandleFetchData = null
}) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  const handleFetchData = async (__query = query) => {
    if(onFetchData){
      setLoading(true)
      try {
        let res = await onFetchData(__query)
        let data = onInitData ? await onInitData(res.data, __query) : res.data 
        setData(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.errorRequest(error)
      }
    }
  }

  useEffect(() => {
    if(getHandleFetchData) getHandleFetchData(handleFetchData)
  }, [])

  useEffect(() => {
    handleFetchData();
  }, [query])

  return (
    <div className={`mpk-data-view-summaries mpk-full full-width mpk-flex align-start wrap ${className}`}>
      {loading && !data ? 
        <div className="mpk-paper mpk-full full-width">
          <LoaderInfo>{t.translate('mpk.sentence.loadingData')}</LoaderInfo> 
        </div>
      : (
        data ? data.map((d, i) => (
          <Summary 
            key={`${baseId}-summary-${i}`}
            className="flex mpk-margin-S margin-right"
            data={d}
            render={render}
          />
        )) : <Hint>Data is not found</Hint>
      )}
    </div>
  )
}

export default Summaries
